import React from 'react'
import { connect } from 'formik'
import styled from 'styled-components'
import {
  allPass,
  lt,
  lte,
  gt,
  gte,
  equals,
  compose,
  converge,
  map,
  prop,
  o,
  __,
  propOr,
  propSatisfies,
  call,
  tap,
  always
} from 'ramda'
import StoryblokInput from 'components/StoryblokInput'

const comparators = {
  lt,
  lte,
  gt,
  gte,
  eq: equals
}

const Container = styled.table`
  width: 100%;
`
const Row = styled.tr`
  width: 100%;
`
const LabelsCol = styled.td`
  white-space: nowrap;
  :nth-child(n + 2) {
    :before {
      content: ' / ';
    }
  }
`
const InputsCol = styled.td`
  flex: 1;
  width: 100%;
`
const Inputs = styled.div`
  display: flex;
  align-items: center;
  > div {
    padding: 8px;
  }
`

function StoryblokInputGroup ({ data, formik }) {
  return (
    <Container>
      <tbody>
        {data?.inputs
          ?.filter(
            compose(
              converge(call, [allPass, always(formik.values)]),
              map(
                converge(propSatisfies, [
                  converge(call, [
                    o(prop(__, comparators), prop('relation')),
                    prop('value')
                  ]),
                  prop('name')
                ])
              ),
              propOr([], 'condition')
            )
          )
          .map(input => input.inputs || [input])
          .map(inputs => (
            <Row key={inputs.id}>
              {data.labels === 'left' && (
                <LabelsCol>
                  {inputs.map(input => (
                    <label htmlFor={input.name} key={input.name}>
                      {input.label}
                    </label>
                  ))}
                </LabelsCol>
              )}
              <InputsCol>
                <Inputs>
                  {inputs.map(inp => (
                    <StoryblokInput
                      key={inp.id}
                      data={inp}
                      noLabel={data.labels === 'left'}
                    />
                  ))}
                </Inputs>
              </InputsCol>
            </Row>
          ))}
      </tbody>
    </Container>
  )
}

export default connect(StoryblokInputGroup)
